const onRouteUpdate = new CustomEvent('routeLoad');
const onPreRouteUpdate = new CustomEvent('routeUnload');

function tracking(location) {
  if (process.env.NODE_ENV !== `production` || typeof gtag !== `function`) {
    return null;
  }

  const pathIsExcluded =
    location &&
    typeof window.excludeGtagPaths !== `undefined` &&
    window.excludeGtagPaths.some((rx) => rx.test(location.pathname));

  if (pathIsExcluded) return null;

  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    const pagePath = location ? location.pathname + location.search + location.hash : undefined;
    window.gtag(`event`, `page_view`, { page_path: pagePath });
  };

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32);
  }

  return null;
}

function animate(elm) {
  const { classList, dataset } = elm;

  if (!dataset.settings) {
    return;
  }

  try {
    const settings = JSON.parse(dataset.settings.replace(/@/g, '"'));
    const animation = settings._animation || settings.animation || 'none';

    if (animation === 'none') {
      classList.remove('elementor-invisible');
      return;
    }

    const animationDelay = settings._animation_delay || settings.animation_delay || 500;

    setTimeout(function () {
      classList.remove('elementor-invisible');
      classList.add('animated');
      classList.add(animation);
    }, animationDelay);
  } catch (_) {
    console.error('Error:', { error: _, dataset });
  }
}

function createObserver(elements) {
  let observer = new IntersectionObserver(
    function (entries) {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0.025) {
          animate(entry.target);
          observer.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      threshold: (() => {
        let thresholds = [];
        let numSteps = 10;

        for (let i = 1.0; i <= numSteps; i++) {
          let ratio = i / numSteps;
          thresholds.push(ratio);
        }

        thresholds.push(0);
        return thresholds;
      })(),
    }
  );

  elements.forEach((elm) => {
    observer.observe(elm);
  });
}
function initializeAnimations() {
  const elements = document.querySelectorAll('.elementor-invisible');

  if (typeof IntersectionObserver === 'undefined') {
    return elements.forEach((elm) => {
      elm.classList.remove('elementor-invisible');
    });
  }
  createObserver(elements);
}

exports.onClientEntry = () => {};

exports.onPreRouteUpdate = () => window.dispatchEvent(onPreRouteUpdate);

exports.onRouteUpdate = ({ location }) => {
  window.__navigatingToLink = false;
  tracking(location);
  document.body.classList.remove('body__scroll--disabled');
  initializeAnimations();
  window.dispatchEvent(onRouteUpdate);
};

exports.shouldUpdateScroll = () => {
  if (window.__navigatingToLink === true) {
    return [0, 0];
  }
  return true;
};
