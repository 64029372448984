module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto","Squada One"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Indefense.de","short_name":"Indefense.de","start_url":"/","background_color":"#131313","theme_color":"#131313","display":"minimal-ui","icon":"src/assets/indefense-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"159a000877f47ab4491ce1921998f623"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
